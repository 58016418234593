import Welcome from "./welcome/Welcome";
import AboutUs from "./about-us/AboutUs";
import { BookingFilter } from './booking-filter/BookingFilter';
import { OurServices } from './our-services/OurServices';
import { Promo } from "./promo/Promo";
import { GuestComments } from './guest-comments/GuestComments.js';

function Home() {
  return (
    <>
      <Welcome />
      <BookingFilter />
      <AboutUs />
      <OurServices />
      <Promo />
      <GuestComments />
    </>
  );
}

export default Home;
