import Gallery from "./gallery/Gallery";
import GreenStamp from "./green-stamp/GreenStamp";
import KnowUs from "./know-us/KnowUs";
import TopBanner from "../shared/components/top-banner/TopBanner";
import Banner from '../images/top-banner.jpg';

function Main() {
  return (
    <>
      <TopBanner content="Sobre nosotros" img={Banner}/>
      <Gallery />
      <GreenStamp />
      <KnowUs />
    </>
  )
}

export default Main;
