import { Container, Row } from "react-bootstrap";
import "./GreenStamp.scss";
import greenStamp from "../../images/hotel/green-stamp.png";

const GreenStamp = () => {
    return (
      <section className="GreenStamp">
        <Container>
          <Row>
            <div className="col-lg-6">
              <img
                className="GreenStamp__logo"
                src={greenStamp}
                alt="Logo sello verde"
              ></img>
            </div>
            <div className="col-lg-6">
              <p>
                Contamos con los sellos ecológicos que certifican nuestra labor
                responsable y amigable con el medioambiente. Contamos con los
                sellos ecológicos que certifican nuestra labor responsable y
                amigable con el medioambiente.
              </p>
            </div>
          </Row>
          <Row className="GreenStamp__items">
            <div className="col-lg-6">
              <ul>
                <li>Sensores de movimiento en los pasillos.</li>
                <li>Retardo de lavado de sábanas y toallones.</li>
                <li>Separación de los residuos.</li>
                <li>Aire acondicionado en 24° en áreas comunes.</li>
                <li>Señalética</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul>
                <li>
                  Protocolo interno de consumo consciente de energía eléctrica,
                  dentro y fuera del hotel.
                </li>
                <li>Jabón sin envoltorio y shampoo duo</li>
                <li>Lámparas led</li>
                <li>TV led en las habitaciones</li>
                <li>
                  Reemplazo por tecnología inverter en aires acondicionados
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </section>
    );
}

export default GreenStamp;