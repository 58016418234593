import TopBanner from "../shared/components/top-banner/TopBanner";
import { BookingFilterCommon } from "../shared/components/booking-filter-common/BookingFilterCommon";
import './Room.scss';
import { ServiceInfo } from "../shared/components/service-info/ServiceInfo";
import { Coffee, Wifi, Air, Fridge } from '../images/services-logo';
import ImageGallery from "react-image-gallery";
import Button from "../shared/components/button/Button";
import { RoomCards } from "../shared/components/room-cards/RoomCards";
import { FollowingButton } from "../shared/components/following-button/FollowingButton";
import { useEffect, useState } from "react";
import { rooms } from "../data/Rooms";

export const Room = (props) => {
  let [heightForButton, setHeightForButton] = useState(0);
  const [roomType, setRoomType] = useState('double');

  useEffect(() => {
    if (document.querySelector('.TopBanner')) {
      setHeightForButton(document.querySelector('.TopBanner').clientHeight + document.querySelector('.NavbarLight').clientHeight);
    }

    setRoomType(props.location.search.split('=')[1]);
  }, [props])

  const items = [
    {
      logo: Coffee,
      texts: ['Desayuno incluido', 'Cafe o te', 'Facturas | Frutas']
    },
    {
      logo: Wifi,
      texts: ['Alta velocidad', 'Home Office', 'Entrenimiento']
    },
    {
      logo: Air,
      texts: ['Aire frío/calor', 'Ventilador']
    },
    {
      logo: Fridge,
      texts: ['Frigobar', 'TV']
    }
  ]

  const paragraphs = [
    'Te mostramos el detalle de cada habitación, para que puedas decidir cuál opción es mejor para vos.'
  ]

  const cards = rooms.filter(room => room.type !== roomType);

  const selectedRoom = rooms.filter(room => room.type === roomType)[0];

  const images = selectedRoom.images && selectedRoom.images.map(image => {
    return {
      original: image,
      thumbnail: image
    }});

  return <>
    <TopBanner content={selectedRoom} img={selectedRoom.images[1]}/>
    <div className="Room__data">
      <div className="container">
        <div className="row  Room__filter">
          <div className="col-12 col-sm-10">
            <BookingFilterCommon/>
          </div>
        </div>
        <FollowingButton top="160px" height={heightForButton}/>
        <div className="row Room__services">
          <div className="col-12 col-sm-6">
            <div className="services-container">
              {items.map((item, index) => 
                <div key={`room-${index}`} className="services-container__item">
                  <ServiceInfo logo={item.logo} texts={item.texts}></ServiceInfo>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <h5 className="gallery">Galería de imágenes</h5>
            <ImageGallery items={images} showPlayButton={false} />
          </div>
        </div>
      </div>
      {/* <div className="Room__protocol">
        <div className="container">
          <div className="row align-items">
            <div className="col-sm-3 col-8 padding-m">
              <h4>Preguntas frecuentes</h4>
            </div>
            <div className="col-sm-4 col-8 padding-m">
              <p className="m-0">Despejá tus dudas en el siguiente pdf, con las preguntas frecuentes de nuestros clientes.</p>
            </div>
            <div className="col-sm-2 col-6 padding-m">
              <div className="button">
                <Button content="Ver más" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container Room__cards">
        <RoomCards paragraphs={paragraphs} cards={cards} title="Otras habitaciones" />
      </div>
    </div>
  </>
} 