import { Container, Row } from "react-bootstrap"
import "./KnowUs.scss";

const KnowUs = () => {
    return (
      <section className="KnowUS">
        <Container>
          <Row className="mb-5">
            <div className="col-lg-6">
              <h5 className="KnowUs__title">¡Vení a conocernos!</h5>
              <p className="KnowUs__desc">
                Cada espacio está diseñado con un sentido estético, teniendo en
                cuenta todos los detalles de comodidad y excelencia que nos
                ubica muy por encima de la media dentro de la categoría.
              </p>
              <p className="KnowUs__desc">
                Está planificado para que todos los pasajeros encuentren lo que
                buscan cuando eligen un sitio de su nivel para hospedarse, y
                están predispuestos a encontrarlo con las menores tarifas del
                sector. Tenemos “ese toque” que siempre ofrece un poco más.
              </p>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/LStNMBDaguk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Row>
        </Container>
      </section>
    );
}

export default KnowUs;