import './Welcome.scss';
import RightArrow from '../../images/Right-arrow.svg';
import { useEffect, useState } from 'react';
import Slider1 from '../../images/slider-01.jpg';
import Slider2 from '../../images/slider-02.jpg';
import Slider3 from '../../images/slider-03.jpg';

function Welcome() {
  const sliders = [
    {
      h1: "Bienvenidos al \nHotel Necof",
      p: "Trabajamos para brindar un servicio diferenciado, porque alojamientos hay muchos, pero un espacio donde cada huésped es especial y un equipo capacitado para compartir experiencias únicas, hay muy pocos.",
      image: Slider1,
    },
    {
      h1: "Descanso y Trabajo. Disfruta siempre",
      p: "El hidromasaje resulta ideal para liberar tensiones, combatir la ansiedad y el estrés. Tu merecido relax en nuestra Habitación Doble Luxury con sales y espuma de baño.",
      image: Slider2,
    },
    {
      h1: "Reserva directo a la mejor tarifa",
      p: "Contratar directo con el hotel siempre es mejor. Podes hacerlo ahora “click” en reservar o comunicándote con nosotros para promociones vigentes, cotizaciones a delegaciones, tarifas singles para empresas. La atención personalizada que esperas.",
      image: Slider3,
    },
  ];

  const [selectedSliderIndex, setSelectedSliderIndex] = useState(0);

  const scrollPage = () => {
    const element = document.querySelector('.swiper-wrapper');
    let index = 0;
    if (selectedSliderIndex < sliders.length - 1) {
      index = selectedSliderIndex + 1; 
      element.scrollLeft += element.offsetWidth;
    } else {
      element.scrollLeft = 0;
    }

    setSelectedSliderIndex(index);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollPage();
    }, 4000);
    
    return () => {
      clearTimeout(timer);
    };
  })

  const getBackgroundImage = (slider) => {
    return {
      backgroundImage: `url(${slider.image})`,
      zIndex: slider === sliders[selectedSliderIndex] ? 1 : 0
    }
  }

  return (
    <div className="Welcome">
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {sliders.map((slider, index) =>
            <div className="swiper-slider" key={`slider-${index}`}>
              <div className="container" style={{ height: '100%', display: 'block' }}>
                <div className="row Welcome__row">
                  <div className="Welcome__description col-md-6 col-12">
                    <h1 className="title">{slider.h1}</h1>
                    <p>{slider.p}</p>
                  </div>
                </div>
                <div className="Welcome__gallery" style={getBackgroundImage(slider)}></div>
              </div>
            </div>
          )}
        </div>
        <div className="swiper-next">
          <img className="right-arrow" src={RightArrow} alt="arrow" onClick={() => scrollPage()}></img>
          <div className="counter">
            <p>0{selectedSliderIndex + 1}/</p>
            <p>0{sliders.length}</p>
          </div>
        </div>
      </div>

    </div>);
}

export default Welcome;