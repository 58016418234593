import { Cuadruple1, Cuadruple2, Cuadruple3, Cuadruple4 } from "../images/rooms/cuadruple";
import {
  Doble1,
  Doble2,
  Doble3,
  Doble4,
  Doble5,
  Doble6,
  Doble7,
  Doble8,
  Doble9
} from "../images/rooms/double";
import { DobleLuxury1, DobleLuxury2, DobleLuxury3 } from "../images/rooms/double-luxury";
import { Triple1, Triple2, Triple3, Triple4 } from "../images/rooms/triple";

export const rooms = [
  {
    "price": "Habitación doble",
    "description": "Habitación doble",
    "type": "double",
    "services": [
      "Desayuno incluido",
      "Wifi gratuito",
      "Cochera"
    ],
    "images": [Doble1, Doble2, Doble3, Doble4, Doble5, Doble6, Doble7, Doble8, Doble9]
  },
  {
    "price": "Habitación doble luxury",
    "description": "Habitación doble luxury",
    "type": "double-luxury",
    "services": ["Desayuno incluido", "Wifi gratuito", "Cochera 24hs"],
    "images": [DobleLuxury1, DobleLuxury2, DobleLuxury3]
  },
  {
    "price": "Habitación triple",
    "description": "Habitación triple",
    "type": "triple",
    "services": ["Desayuno incluido", "Wifi gratuito", "Cochera 24hs"],
    "images": [Triple4, Triple2, Triple3, Triple1]
  },
  {
    "price": "Habitación cuadruple",
    "description": "Habitación cuadruple",
    "type": "cuadruple",
    "services": ["Desayuno incluido", "Wifi gratuito", "Cochera 24hs"],
    "images": [Cuadruple4, Cuadruple2, Cuadruple3, Cuadruple1]
  }
]