import './Button.scss';
import React from 'react';

const Button = (props) => {
  const classes = ['Button'];

  if (props.className) {
    const fatherClasses = props.className.split(' ');
    classes.push(...fatherClasses);
  }

  if (!props.noPadding) {
    classes.push('Button--sides-padding')
  };

  const type = props.type || 'primary';

  classes.push(`Button--${type}`);

  return <button className={classes.join(' ')} onClick={props.action} type={props.type}>{props.content}</button>;
}

export default Button;