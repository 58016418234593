import {
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import "./App.scss";
import AboutUs from "./about-us/Main";
import Home from "./home/Home";
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { Room } from "./room/Room";
import { Contact } from "./contact/Contact";
import NavbarLight from "./shared/components/navbar-light/NavbarLight";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import ScrollIntoView from "./shared/components/scroll-into-view/ScrollIntoView";
import Footer from "./shared/components/footer/Footer";

library.add(far, fas, fab);

const App = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <NavbarLight />
        <ScrollIntoView/>
          <Switch>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/nosotros" component={AboutUs}></Route>
            <Route exact path="/habitacion" component={Room}></Route>
            <Route exact path="/contacto" component={Contact}></Route>
          </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
