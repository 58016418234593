import "./TopBanner.scss";

const TopBanner = (props) => {
  return (
    <section
      className="TopBanner"
      style={{ backgroundImage: `url('${props.img}')`, backgroundPosition: props.content.type === 'triple' || props.content.type === 'cuadruple' ? 'bottom': 'center' }}>
      <div className="TopBanner__gradient"></div>
      <div className="TopBanner__title">{props.content.description}</div>
    </section>
  );
};

export default TopBanner;
