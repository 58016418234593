import './AboutUs.scss';
import Button from '../../shared/components/button/Button';
import WhatsAppWhite from '../../images/wsp-white.svg';
import Square from '../../images/mask-group.png';
import { TextContainer } from '../../shared/components/text-container/TextContainer';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const AboutUs = () => {
  const [buttonStyle, setButtonStyle] = useState({});
  const isMounted = useRef(false);
  const history = useHistory();

  const handleScroll = useCallback(() => {
    if (isMounted.current) {
      const welcomeDivHeight = document.querySelector('.Welcome').clientHeight + document.querySelector('.NavbarLight').clientHeight;
      const scrollTop = window.scrollY;
      if (scrollTop > welcomeDivHeight) {
        setButtonStyle({ position: 'fixed', top: '48.5px', zIndex: 2 })
      } else {
        setButtonStyle({})
      }
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;
    window.addEventListener('scroll', handleScroll);

    return () => isMounted.current = false;
  }, [handleScroll]);

  const paragraphs = [
    "Hotel Necof Inauguro el 1° de Noviembre de 2008, En una construcción nueva, pensada para este fin, con todos los detalles prolijamente concebidos para reunir el óptimo de confort para el huésped que viene en viaje de negocios, solo o con su familia.",
    "Cada espacio está diseñado con un sentido estético, teniendo en cuenta todos los detalles de comodidad y excelencia que nos ubica muy por encima de la media dentro de la categoría.",
  ];

  const button = <Button content="Ver más" noPadding="true" action={() => history.push('/nosotros')} />;

  return <div className="AboutUs">
    <button className="button" style={buttonStyle}>
      <div className="button__wrapper">
        <img className="button__logo" src={WhatsAppWhite} alt="Logo"></img>
        <a href="https://wa.link/9f0f3g"
          target="_blank"
          rel="noopener noreferrer">
          Consultas
        </a>
      </div>
    </button>
    <div className="container pt-5">
      <div className="row">
        <div className="col-lg-7 col-12 position-relative">
          <div>
            <div className="AboutUs__image"></div>
            <img className="AboutUs__square" src={Square} alt="square"></img>
          </div>
        </div>
        <div className="col-lg-5 col-12 d-flex align-items-center">
          <TextContainer title="Sobre nosotros" paragraphs={paragraphs} button={button}/>
        </div>
    </div>
    </div>
  </div>
}

export default AboutUs;