/* eslint-disable jsx-a11y/iframe-has-title */

import React from 'react';

function Map() {
  return (
    <div>
      <iframe
        className="Contact__map" 
        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d836.845369717806!2d-60.629618730396686!3d-32.967335873187515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sit!4v1695229627586!5m2!1ses-419!2sit" 
        width="600" 
        height="450" 
        style={{ border: '0' }} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Map;
