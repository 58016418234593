import { Container, Row } from "react-bootstrap";
import "./Gallery.scss";
import ImageGallery from "react-image-gallery";
import {
  Hotel01,
  Hotel02,
  Hotel03,
  Hotel04,
  Hotel05,
  Hotel06,
} from "../../images/service-gallery";
import { useState } from "react";

const images = [
  {
    original: Hotel01,
    thumbnail: Hotel01,
  },
  {
    original: Hotel02,
    thumbnail: Hotel02,
  },
  {
    original: Hotel03,
    thumbnail: Hotel03,
  },
  {
    original: Hotel04,
    thumbnail: Hotel04,
  },
  {
    original: Hotel05,
    thumbnail: Hotel05,
  },
  {
    original: Hotel06,
    thumbnail: Hotel06,
  },
];

const Gallery = () => {
  let [loadedImages, setLoadedImages] = useState(images.map(image => image.original));
  let [loadingImages, setLoadingImages] = useState(true);

  const onImageLoad = (event) => {
    const pendingImages = loadedImages.filter(image => !event.currentTarget.currentSrc.includes(image));
    setLoadedImages(pendingImages);

    if (!pendingImages.length) {
      setLoadingImages(false);
    }
  }

  return (
    <section
      className="Gallery"
      style={{ display: loadingImages ? "none" : "block" }}
    >
      <Container>
        <Row>
          <div className="col-lg-6">
            <h5 className="Gallery__title">Galería de imágenes</h5>
            <div className="Gallery__wrapper">
              <ImageGallery
                items={images}
                showPlayButton={false}
                onImageLoad={(event) => onImageLoad(event)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="Gallery__desc">
              <p>
                Hotel Necof es una empresa familiar comprometida con la
                satisfacción de nuestros clientes, ofreciéndoles calidad,
                confort y precio. Desarrollamos nuestra actividad en un marco de
                compromiso con la sociedad y respeto por el medio ambiente,
                somos el primer hotel de Rosario en recibir el Sello Verde del
                programa de la Secretaría de Medioambiente del Municipio.
              </p>
              <p>
                El hotel cuenta con habitaciones dobles, triples y cuádruples
                tipo dpto. Habitación con baño adaptado. Hab. Doble Luxury con
                bañera de hidromasaje para dos personas. Cocheras propias
                techadas dentro del mismo edificio, desayuno, ascensor,
                cobertura médica de emergencia, circuito cerrado de seguridad,
                WIFI, escalera para salida de emergencia, sistema de detección
                de incendio.
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default Gallery;
