import "./Footer.scss";
import WhiteIso from "../../../images/white-iso.png";
import WhatsappIcon from "../../../images/wsp-white.svg";
import emailIcon from "../../../images/email.svg";
import locationIcon from "../../../images/locationIcon.svg";
import facebook from "../../../images/facebook.svg";
import instagram from "../../../images/instagram.svg";
import faq from "../../../images/faq.svg";
import dataFiscal from "../../../images/dataFiscal.png";

const Footer = () => {
  return (
    <>
      <section className="Footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img className="Footer__logo" src={WhiteIso} alt="Logo"></img>
              <ul className="Footer__items">
                <li>Datos de contacto</li>
                <li>
                  <a
                    href="https://wa.link/9f0f3g"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Footer__icon"
                      src={WhatsappIcon}
                      alt="WhatsappIcon"
                    ></img>
                    +54 9 341 519 8794
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:contacto@hotelnecof.com.ar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Footer__icon"
                      src={emailIcon}
                      alt="emailIcon"
                    ></img>
                    contacto@hotelnecof.com.ar
                  </a>
                </li>
                <li>
                  <a
                    href="https://goo.gl/maps/C79ZgaGxGGo53yGC8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Footer__icon"
                      src={locationIcon}
                      alt="locationIcon"
                    ></img>
                    Necochea 2356 | Rosario | Santa Fe
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 col-8 d-flex justify-content-lg-end align-items-end">
              <ul className="Footer__items">
                <li>Redes sociales</li>
                <li>
                  <a
                    href="https://www.instagram.com/hotelnecof/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Footer__icon"
                      src={instagram}
                      alt="instagram"
                    ></img>
                    @hotelnecof
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/hotelnecof"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="Footer__icon"
                      src={facebook}
                      alt="facebook"
                    ></img>
                    /hotelnecof
                  </a>
                </li>
                {/* <li>
                  <img
                    className="Footer__icon"
                    src={faq}
                    alt="preguntas frecuentes"
                  ></img>
                  Preguntas frecuentes
                </li> */}
              </ul>
            </div>
            <div className="col-md-4 col-4 d-flex justify-content-lg-end align-items-end">
              <ul className="Footer__items">
                <li>Data Fiscal</li>
                <img
                  className="dataFiscal"
                  src={dataFiscal}
                  alt="Data Fiscal"
                ></img>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="Copy">
        <div className="container d-flex align-items-center justify-content-between">
          <p>Hotel Necof Rosario | Todos los derechos reservados</p>
          <a
            href="https://infuze.com.ar/"
            target="_blank"
            rel="noopener noreferrer"
          >
            2023 | Desarrollado por infuze
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
