import './ServiceInfo.scss';

export const ServiceInfo = (props) => {
  return <div className="item-container">
    <div className="logo-container d-flex justify-content-center align-items-center">
      <img className="logo-container__image" src={props.logo} alt="item-logo"></img>
    </div>
    <div>
      {props.texts.map((text, index) => 
        <p key={`text-${index}`} className="text">{text}</p>
      )}
    </div>
  </div>
}