import './BookingFilterCommon.scss';
import Button from '../button/Button';
import UserLogo from '../../../images/user.svg';
import Form from 'react-bootstrap/Form';
import Calendar from '../../../images/calendar.svg';
import { Datepicker } from '../datepicker/Datepicker';
import { useState } from 'react';

export const BookingFilterCommon = () => {
  const [adultsValue, setAdultsValue] = useState();
  const [childsValue, setChildsValue] = useState();
  const [checkInDate, setCheckInDate] = useState();
  const [checkOutDate, setCheckOutDate] = useState();

  const formatDate = (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
  
    return [date.getFullYear(),
            (mm > 9 ? '' : '0') + mm,
            (dd > 9 ? '' : '0') + dd
           ].join('-');
  };

  const book = () => {
    const data = {
      adultos: adultsValue,
      ninios: childsValue,
      fechaDesde: formatDate(checkInDate),
      fechaHasta: formatDate(checkOutDate)
    };

    var str = [];
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        str.push(encodeURIComponent(property) + "=" + encodeURIComponent(data[property]));
      }
    }
    window.open(`https://www.todoalojamiento.com/portal/es?idHotel=724&forzarLimpiar=true&monedaISO=ARS&${str.join('&')}`, '_blank');
  }

  return <div className="BookingFilterCommon__wrapper">
    <div className="row">
      <div className="col-lg-2 col-6 border-right">
        <div className="BookingFilterCommon__filter">
          <img className="image" src={UserLogo} alt="logo"></img>
          <Form.Select onChange={element => setAdultsValue(element.target.value)} className="BookingFilterCommon__select" aria-label="Personas">
            <option>Adultos</option>
            <option value="1">Uno</option>
            <option value="2">Dos</option>
            <option value="3">Tres</option>
            <option value="4">Cuatro</option>
            <option value="5">Cinco</option>
          </Form.Select>
        </div>
      </div>
      <div className="col-lg-2 col-6 border-right">
        <div className="BookingFilterCommon__filter">
          <img className="image" src={UserLogo} alt="logo"></img>
          <Form.Select onChange={element => setChildsValue(element.target.value)} className="BookingFilterCommon__select" aria-label="Personas">
            <option>Niños</option>
            <option value="1">Uno</option>
            <option value="2">Dos</option>
            <option value="3">Tres</option>
            <option value="4">Cuatro</option>
            <option value="5">Cinco</option>
          </Form.Select>
        </div>
      </div>
      <div className="col-lg-2 col-6 border-right">
        <div className="BookingFilterCommon__filter">
          <img className="image" src={Calendar} alt="calendar"></img>
          <Datepicker content="Check-in" setValue={setCheckInDate}/>
        </div>
      </div>
      <div className="col-lg-2 col-6 border-right">
        <div className="BookingFilterCommon__filter">
          <img className="image" src={Calendar} alt="calendar"></img>
          <Datepicker content="Check-out" setValue={setCheckOutDate}/>
        </div>
      </div>
      <div className="BookingFilterCommon__button col-lg-4 col-12">
        <Button content="Reservar" action={() => book()}/>              
      </div>
    </div>
  </div>
}