import './Card.scss';
import Button from '../button/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

export const Card = (props) => {
  const secondary = 'secondary';

  const [buttonType, setButtonType] = useState(secondary);
  const { room } = props;
  const history = useHistory();

  return <div className="Card" onMouseEnter={() => setButtonType('secondary-dark')} onMouseLeave={() => setButtonType(secondary)}>
    <div className="Card__image" style={{ backgroundImage: `url("${room.images[0]}")` }}></div>
    <div className="Card__info">
      {/* <p className="type">{room.price}</p> */}
      <div className="room">
        <h1 className="room__price mt-3">{room.description}</h1>
        {room.services.map((service, index) => 
          <p className="room__service" key={`service-${index}`}>{service}</p>
        )}
      </div>
      <div className="Card__button">
        <Button content="Ver detalle" type={buttonType} action={() => history.push(`/habitacion?type=${room.type}`)}/>
      </div>
    </div>
  </div>
}