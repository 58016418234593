import { useCallback, useEffect, useRef, useState } from 'react';
import './FollowingButton.scss';
import WhatsAppWhite from '../../../images/wsp-white.svg';

export const FollowingButton = (props) => {
  const [buttonStyle, setButtonStyle] = useState({});
  const isMounted = useRef(false);

  const handleScroll = useCallback(() => {
    if (isMounted.current) {
      const welcomeDivHeight = props.height;
      const scrollTop = window.scrollY;
      if (scrollTop + 1 > welcomeDivHeight) {
        setButtonStyle({ position: 'fixed', top: props.top, zIndex: 2 })
      } else {
        setButtonStyle({ top: props.top })
      }
    }
  }, [props.top, props.height]);

  useEffect(() => {
    isMounted.current = true;
    window.addEventListener('scroll', handleScroll);

    return () => isMounted.current = false;
  }, [handleScroll]);

  return <button className="FollowingButton" style={buttonStyle}>
    <div className="FollowingButton__wrapper">
      <img className="FollowingButton__logo" src={WhatsAppWhite} alt="Logo"></img>
      <a href="https://wa.link/9f0f3g"
        target="_blank"
        rel="noopener noreferrer">
        Consultas
      </a>
    </div>
  </button>
}