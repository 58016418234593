import { TextContainer } from "../../shared/components/text-container/TextContainer";
import './GuestComments.scss';
import Button from "../../shared/components/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Quotes from '../../images/left-quote.png';
import { useState } from "react";
import Star from '../../images/star.svg';

export const GuestComments = () => {
  const paragraphs = ["Conocé lo que dicen nuestros huéspedes sobre nosotros."];
  const comments = [
    {
      comment: 'Atención excelente, habitaciones muy cómodas y espaciosas, el desayuno un espectáculo!!',
      person: {
        name: 'Julian Quevedo',
        punctuation: '5/5',
        image: 'https://lh3.googleusercontent.com/a-/AOh14Gi8B7j_G1efD1zVALZ1pf1xVGZDvZim2tmZ4JNSuRY=s80-c0x00000000-cc-rp-mo-ba2-br100'
      }
    },
    {
      comment: 'Buen hotel, acorde a su precio, brinda muy buena atención, con estacionamiento propio.',
      person: {
        name: 'Gabriel Comelli',
        punctuation: '5/5',
        image: 'https://lh3.googleusercontent.com/a-/AOh14GhY5AwJOaGTPPDN-OfUQyg_aAuQVZK-47udZqCs=s80-c0x00000000-cc-rp-mo-ba5-br100'
      } 
    },
    {
      comment: 'Muy lindo Hotel, atencion espectacular y precios muy lógicos.',
      person: {
        name: 'Juan Torres',
        punctuation: '5/5',
        image: 'https://lh3.googleusercontent.com/a-/AOh14Gj0L28kZhwmb9jTVyX15w_199FsuL_vvHbvz_w4V3Y=s80-c0x00000000-cc-rp-mo-ba4-br100'
      } 
    },
    {
      comment: 'Cómo siempre que vamos,muy buena la atención y habitaciones lindas y cómodas!!!',
      person: {
        name: 'Martin Varela',
        punctuation: '5/5',
        image: 'https://lh3.googleusercontent.com/a-/AOh14GjUrtE0x10cw3qu9g8aMT4eTFEFgzz8SUOwFhFv=s80-c0x00000000-cc-rp-mo-br100'
      } 
    },
    {
      comment: 'Me encantó! La verdad excelente atención!! Precio razonable, aunque gaste más de lo que quería, valió totalmente la pena.',
      person: {
        name: 'Relys Oviedo',
        punctuation: '4/5',
        image: 'https://lh3.googleusercontent.com/a-/AOh14GgOhr2BCyc3EKA8266e_ht8exTwaSpJzWookBsBgaU=s80-c0x00000000-cc-rp-mo-ba4-br100'
      } 
    }
  ]

  const [selectedCommentIndex, setSelectedCommentIndex] = useState(0);

  const getCommentIndex = (index, side) => {
    if (comments[index]) {
      setSelectedCommentIndex(index);
      return;
    }

    setSelectedCommentIndex(side === 'right' ? 0 : comments.length - 1);
  }

  const textContainerTitle = <div className="GuestComments__title">
    <h1 className="title">Comentarios de los huéspedes</h1>
    {[].concat(Star, Star, Star).map((star, index) => {
      return <img className="star" src={star} alt="star" key={`star-${index}`}></img>
    })}
  </div>

  return <div className="GuestComments">
    <div className="container">
      <div className="row">
        <div className="col-md-7 col-sm-12">
          <TextContainer customTitle={textContainerTitle} paragraphs={paragraphs}/>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-lg-6 col-12">
          <div className="GuestComments__image">
            <div className="image"></div>
            <div className="square"></div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="GuestComments__comments">
            <div className="arrows">
              <Button 
                className="arrow" 
                content={<FontAwesomeIcon icon={["far", "arrow-alt-circle-left"]} />} 
                noPadding="true"
                action={() => getCommentIndex(selectedCommentIndex - 1, 'left')}/>
              <Button 
                className="arrow"
                content={<FontAwesomeIcon icon={["far", "arrow-alt-circle-right"]} />} 
                noPadding="true"
                action={() => getCommentIndex(selectedCommentIndex + 1, 'right')}/>
            </div>
            <img src={Quotes} alt="quotes" className="quotes"></img>
            <div className="comments">
              <p className="comments__comment">{comments[selectedCommentIndex].comment}</p>
              <div className="comments__person">
                <div className="avatar" style={{ backgroundImage: `url(${comments[selectedCommentIndex].person.image})` }}></div>
                <div className="info">
                  <p className="info__name text">{comments[selectedCommentIndex].person.name}</p>
                  <p className="info__name text">{comments[selectedCommentIndex].person.punctuation}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}