import './TextContainer.scss';

export const TextContainer = (props) => {
  return <div className="TextContainer">
    {!props.customTitle ? 
      <h1 className="title">{props.title}</h1> :
      props.customTitle
    }
    <hr className="separator"></hr>
    {props.paragraphs.map((paragraph, index) => {
      return <p className={index === 0 ? "first-paragraph" : null} key={`paragraph-${index}`}>{paragraph}</p>
    })}
    { props.button }
  </div>
}