import Button from '../../shared/components/button/Button';
import './Form.scss';
import { Input } from './Input';
import { useForm, Controller } from "react-hook-form";
import { emailKey } from '../../shared/mailing/emailKey';
import emailjs from 'emailjs-com';

export const Form = () => {
  const { formState: { errors }, handleSubmit, setValue, control} = useForm();

  const onSubmit = (data, e) => {
    emailjs.send(emailKey.SERVICE_ID, emailKey.TEMPLATE_ID, data, emailKey.USER_ID)
      .then((result) => {
        alert("Mensaje enviado! Nos contactaremos a la brevedad. Muchas gracias", result.text);
        e.target.reset();
      },
      (error) => {
        alert("Ocurrió un error, intente más tarde.", error.text);
      });
  };

  return <div className="Form">
    <form onSubmit={handleSubmit(onSubmit)}>
      <h5 className="Form__title">Formulario de contacto</h5>
      <Controller
        control={control}
        rules={{
         required: true
        }}
        render={() => (
          <Input name="firstName" setValue={setValue} placeholder="Nombre" label="first-name" errors={errors}/>
        )}
        name="firstName"
        defaultValue=""
      />
      <Controller
        control={control}
        rules={{
         required: true
        }}
        render={() => (
          <Input name="lastName" setValue={setValue} placeholder="Apellido" label="last-name" errors={errors}/>
        )}
        name="lastName"
        defaultValue=""
      />
      <Controller
        control={control}
        rules={{
         required: true
        }}
        render={() => (
          <Input name="phoneNumber" setValue={setValue} placeholder="Celular" label="phone-number" errors={errors}/>
        )}
        name="phoneNumber"
        defaultValue=""
      />
      <Controller
        control={control}
        rules={{
         required: true
        }}
        render={() => (
          <Input name="message" setValue={setValue} placeholder="Mensaje" label="message" type="textarea" errors={errors}/>
        )}
        name="message"
        defaultValue=""
      />
      <div className="Form__button">
        <Button content="Enviar" type="submit"/>
      </div>
    </form>
  </div>
}