import { Card } from '../card/Card';
import { TextContainer } from '../text-container/TextContainer';
import './RoomCards.scss';

export const RoomCards = (props) => {
  const columns = props.cards.length / 3 === 1 ? 4 : 3;

  const getClassName = () => `col-lg-${columns} col-12 mobile-card`;

  return <>
    <div className="row">
      <div className="col-md-8 col-sm-12">
        <TextContainer title={props.title} paragraphs={props.paragraphs} />
      </div>
    </div>
    <div className="row RoomCards">
      {props.cards.map((card, index) =>
        <div className={getClassName()} key={`card-${index}`}>
          <Card room={card} />
        </div>
      )}
    </div>
  </>
}