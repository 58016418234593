import "./NavbarLight.scss";
import Logo from "../../../images/logo.svg";
import WspIcon from "../../../images/wsp-dark.svg";
import Button from "../button/Button";
import { Navbar, Container, Nav  } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";


const NavbarLight = () => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = (route, section) => {
    if (location.pathname !== '/') {
      history.push(route);
    }
    setTimeout(() => {
      const element = document.getElementsByClassName(section);
      element[0].scrollIntoView(true);
    }, 0)
  }

  return (
    <Navbar className="NavbarLight" bg="light" expand="lg">
      <Container className="NavbarLight__container">
        <Navbar.Brand
          className="NavbarLight__logo"
          onClick={() => history.push("/")}
        >
          <img className="logo" src={Logo} alt="Logo Hotel Necof"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="NavbarLight__links" id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              className="NavbarLight__link"
              onClick={() => history.push("/nosotros")}
            >
              Nosotros
            </Nav.Link>
            <Nav.Link
              className="NavbarLight__link"
              onClick={() => handleClick("/", "OurServices")}
            >
              Servicios
            </Nav.Link>
            <Nav.Link
              className="NavbarLight__link"
              onClick={() => handleClick("/", "Promo")}
            >
              Habitaciones
            </Nav.Link>
            <Nav.Link
              className="NavbarLight__link"
              onClick={() => history.push("/contacto")}
            >
              Contacto
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className="NavbarLight__wsp">
          <img className="icon" src={WspIcon} alt="Logo wsp"></img>
          <a
            href="https://wa.link/9f0f3g"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="number">+54 9 341 519 8794</p>
          </a>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavbarLight;
