import { TextContainer } from '../../shared/components/text-container/TextContainer';
import './OurServices.scss';
import Logo from '../../images/logo.svg';
import { Coffee, Dog, Wifi, Valet, Mask } from '../../images/services-logo';
import { ServiceInfo } from '../../shared/components/service-info/ServiceInfo';

export const OurServices = () => {
  const paragraphs = [
    "Desde el #HotelNecof creemos que cada granito de arena suma para cambiar el mundo.",
    "Por eso desde 2010 nos capacitamos e implementamos certificaciones para mejorar los procesos. En mejora continua de procesos y tecnología nuestro última certificación es el Sello Verde que otorga la Municipalidad de Rosario, para promover las buenas prácticas ambientales.",
  ];

  const items = [
    {
      logo: Coffee,
      texts: ['Desayuno incluido']
    },
    {
      logo: Wifi,
      texts: ['Wifi de alta velocidad']
    },
    {
      logo: Dog,
      texts: ['Pet friendly']
    },
    {
      logo: Valet,
      texts: ['Estacionamiento']
    },
    {
      logo: Mask,
      texts: ['Protocolo COVID']
    }
  ]

  return <div className="OurServices">
    <div className="container">
      <div className="row">
        <div className="col-8 col-12">
          <TextContainer title="Conozca nuestros servicios" paragraphs={paragraphs} />
        </div>
      </div>
      <div className="row OurServices__items-section">
        {items.map((item, index) => 
          <div className="col-lg-2 col-4" key={`item-${index}`}>
            <div className="OurServices__item">
              <ServiceInfo logo={item.logo} texts={item.texts}/>
            </div>
          </div>
        )}
      </div>
      <p className='mt-4 mb-4'>
        El miembro de la familia que no puede faltar. Aceptamos
        mascotas razas caninas pequeñas hasta 5 kg aprox. Una
        mascota por habitación. Por convivencia con todos los
        huéspedes no pueden bajar ni permanecer en el desayuno. No
        pueden quedar solos en la habitación en ningún horario del día.
        Esperamos lo normal de una tenencia responsable.
      </p>
      <div className="row OurServices__logo">
        <img className="image" src={Logo} alt="logo"></img>
      </div>
    </div>
  </div>
}