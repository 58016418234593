import { useEffect, useRef, useState } from 'react';
import './Input.scss';

export const Input = (props) => {
  const inputRef = useRef();
  const wrapperRef = useRef();
  const [inputStyle, setInputStyle] = useState();
  const [wrapperStyle, setWrapperStyle] = useState();

  useEffect(() => {
    setInputStyle({ minHeight: inputRef.current.clientHeight });
    setWrapperStyle({ minHeight: wrapperRef.current.clientHeight + 24 });
  }, [inputRef])

  const inputFocus = event => {
    const parent = event.target.parentElement;
    parent.classList.add('is-active');
    parent.classList.add('is-completed');
  }

  const inputBlur = event => {
    const parent = event.target.parentElement;

    if (!event.target.value) {
      parent.classList.remove('is-completed');
    }

    parent.classList.remove('is-active');
  }

  const keys = {
    'message': 'mensaje',
    'firstName': 'nombre',
    'lastName': 'apellido',
    'phoneNumber': 'celular'
  }

  return <div className="mat-div__wrapper" ref={wrapperRef} style={wrapperStyle}>
    <div className="mat-div" ref={inputRef} style={inputStyle}>
      <label htmlFor={props.label} className="mat-label">{props.placeholder}</label>
      {props.type === 'textarea' ? 
        <textarea 
          name={props.name} 
          type="text"
          className="mat-input"
          id={props.label}
          onFocus={event => inputFocus(event)}
          onBlur={event => inputBlur(event)}
          onChange={(e) => props.setValue(props.name, e.target.value)}/> :
        <input 
          name={props.name} 
          type="text" 
          className="mat-input" 
          id={props.label} 
          onFocus={event => inputFocus(event)} 
          onBlur={event => inputBlur(event)}
          onChange={(e) => props.setValue(props.name, e.target.value)}/>
      }
    </div>
    {props.errors[props.name] && `El ${keys[props.name]} es requerido`}
  </div>
}