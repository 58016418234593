import "./Promo.scss";
import { RoomCards } from "../../shared/components/room-cards/RoomCards";
import { Inquiry } from "../../shared/components/inquiry/Inquiry";
import { rooms } from "../../data/Rooms";

export const Promo = () => {
  const paragraphs = [
    "Contratar directo con el hotel siempre es mejor. Podes hacerlo ahora “click” en reservar o comunicándote con nosotros para promociones vigentes, cotizaciones a delegaciones, tarifas singles para empresas.",
  ];

  const cards = rooms;

  return (
    <div className="Promo">
      <div className="container Promo__cards-container">
        <RoomCards paragraphs={paragraphs} title="Habitaciones" cards={cards} />
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <p>
              Trabajamos para brindar un servicio diferenciado, porque
              alojamientos hay muchos, pero un espacio donde cada huésped es
              especial y un equipo capacitado para compartir experiencias
              únicas, hay muy pocos.
            </p>
          </div>
        </div>
      </div>
      <Inquiry />
    </div>
  );
};
