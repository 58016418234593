import WspLogo from '../../../images/wsp-white.svg';
import './Inquiry.scss';

export const Inquiry = () => {
  return  <div className="Inquiry">
    <div className="background"></div>
    <div className="text">
      <h1 className="text__title">
        <a href="https://wa.link/9f0f3g"
          target="_blank"
          rel="noopener noreferrer">
          No dudes en consultarnos
        </a>
      </h1>
      <img className="text__logo"src={WspLogo} alt="logo"></img>
    </div>
  </div>
}