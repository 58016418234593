import './Contact.scss';
import WspIcon from "../images/wsp-white.svg";
import Map from './map/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Inquiry } from '../shared/components/inquiry/Inquiry';
import { Form } from './form/Form';
import { useEffect, useRef } from 'react';

export const Contact = () => {
  const formRef = useRef(null);

  useEffect(() => {
    if (!window.matchMedia('(max-width: 700px)').matches) {
      const formHeight = formRef.current.clientHeight + 96;

      const conctacInfoElement = document.querySelector('.Contact__info')

      conctacInfoElement.style.height = `${formHeight}px`;
    }
  })

  return (
    <div className="Contact">
      <div className="Contact__info">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-4">
              <h1 className="title">Contacto</h1>
              <p>
                Todos nuestros canales de comunicación. No dudes en consultarnos
              </p>
              <div className="contact-data">
                <h6 className="contact-data__title">Datos del contacto</h6>
                <div className="contact-data__item">
                  <ul>
                    <li>
                      <p className="text p-0">+54 9 341 519 8794</p>
                    </li>
                    <li><p className="text p-0">+54 9 341 4859888</p></li>
                    <li><p className="text p-0">+54 9 341 4828929</p></li>
                  </ul>
                </div>
                <div className="contact-data__item">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["fas", "envelope"]}
                  />
                  <p className="text">contacto@hotelnecof.com.ar</p>
                </div>
                <div className="contact-data__item">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["fas", "map-marker-alt"]}
                  />
                  <p className="text">Necochea 2356 | Rosario | Santa Fe</p>
                </div>
              </div>
              <div className="social-media">
                <p className="social-media__title">Redes sociales:</p>
                <div className="social-media__icons">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["fab", "facebook"]}
                  />
                  <FontAwesomeIcon
                    className="icon"
                    icon={["fab", "instagram"]}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-12 Contact__form" ref={formRef}>
              <Form />
            </div>
          </div>
        </div>
      </div>
      <Inquiry />
      <div className="Contact__location">
        <div className="container">
          <div className="row alignment">
            <div className="col-6">
              <h1 className="title">Ubicación</h1>
            </div>
            <div className="col-4">
              <p>
                Ubicado en el Macrocentro rosarino, en un barrio arbolado de
                calles amplias, sin la congestión de transito de las horas pico
                de la ciudad. Nos preparamos con el mejor ambiente y calidad
                para recibir huéspedes por negocios, descanso y en familia.
                También delegaciones.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Contact__map">
        <Map />
      </div>
    </div>
  );
}