import './Datepicker.scss';
import DownArrow from '../../../images/down-arrow.svg';
import { useState, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Datepicker = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="Datepicker">
      <button className="Datepicker__button" onClick={onClick} ref={ref}>
        {value}
      </button>
      <img className="Datepicker__arrow" src={DownArrow} alt="arrow" onClick={onClick}></img>
    </div>
  ));

  useEffect(() => {
    props.setValue(startDate);
  })

  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      dayClassName={(date) =>
        'day'
      }
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        props.setValue(date);
      }}
      customInput={<ExampleCustomInput />}
    />
  );
};